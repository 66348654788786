<template>
  <div class="user-info-panel">
    <div class="header-user-info-wrap">
      <div class="header-user-basic-wrap">
        <router-link :to="{ name: 'profile' }" class="header-user-basic-head-img">
          <img
            :src="userInfo?.face != '' ? userInfo?.face : defaultAvatar"
            class="user-avatar-placeholder"
          />
        </router-link>
        <div class="header-user-basic-nick-name-wrap">
          <div class="header-name-wrap">
            <div class="header-name-left">
              <span class="header-user-basic-nick-name">{{ userInfo.nick }}</span>
              <div class="vip-badge">
                <img src="@assets/icons/nav/level-1.webp" />
              </div>
            </div>
            <!-- <span class="current-process-num"> <span class="label-text">经验值：</span>95 </span> -->
          </div>
          <!-- <div class="header-user-basic-rank">
            <span class="level">Lv<span class="level-num">0</span></span>
            <a href="/personal/my-membership">
              <div class="process-wrap">
                <div class="process-container">
                  <div class="current-process-box">
                    <div class="current-process" :style="{ width: '10%' }"></div>
                    <div class="current-process-stop">
                      <div class="process-stop-placeholder"></div>
                      <div class="process-gif-placeholder"></div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <span class="level">Lv<span class="level-num">1</span></span>
          </div> -->
        </div>
      </div>
      <!-- <div class="header_user_next_level_tip">加油！还有940分为高级会员</div> -->
      <div class="header-user-info-wallet-wrap">
        <div class="header-user-info-wallet-info">
          <div class="wallet-info">
            <div class="wallet-info-num">
              <div class="wallet-info-up">
                <div class="diamond-icon-placeholder">
                  <img src="@assets/icons/nav/diamond.webp" />
                </div>
                <div class="wallet-info-down">
                  <span>球钻</span><br />{{ userInfo.ball_diamond }}
                </div>
              </div>
            </div>
            <div class="wallet-info-pay-button-wrap">
              <router-link :to="{ name: 'wallet' }" class="wallet-info-pay-button"
                >充值</router-link
              >
            </div>
          </div>
          <div class="wallet-info">
            <div class="wallet-info-num">
              <div class="wallet-info-up">
                <div class="ticket-icon-placeholder">
                  <img src="@assets/icons/nav/ticket.webp" />
                </div>

                <div class="wallet-info-down">
                  <span>球票</span><br />{{ userInfo.ball_ticket }}
                </div>
              </div>
            </div>
            <div class="wallet-info-pay-button-wrap">
              <router-link :to="{ name: 'wallet' }" class="wallet-info-pay-button"
                >转换</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="header-user-info-foot-btn-wrap">
        <router-link :to="{ name: 'profile' }" class="foot-btn personal-center">
          <div>
            <img src="@assets/icons/nav/person.webp" />
          </div>

          <p class="foot-text">个人中心</p>
        </router-link>
        <router-link :to="{ name: 'follow' }" class="foot-btn my-focus">
          <div>
            <img src="@assets/icons/nav/like.webp" />
          </div>

          <p class="foot-text">我的关注</p>
        </router-link>
        <router-link :to="{ name: 'appointment' }" class="foot-btn my-reserve">
          <div>
            <img src="@assets/icons/nav/reserve.webp" />
          </div>

          <p class="foot-text">我的预约</p>
        </router-link>
        <router-link :to="{ name: 'liveApplication' }" class="foot-btn apply-for-anchor">
          <div>
            <img src="@assets/icons/nav/anchor.webp" />
          </div>

          <p class="foot-text">{{ userInfo.isAnchor ? '我要开播' : '申请主播' }}</p>
        </router-link>
      </div>
      <div class="header-user-logout-wrap">
        <div class="header-user-logout">
          <div class="header-user-logout-txt" @click="logout">退出</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { userStore } from '@/stores/user'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const stores = userStore()
const userInfo = computed(() => stores.userInfo)
const router = useRouter()

const defaultAvatar = new URL('@assets/imgs/default-head-1.webp', import.meta.url).href

const logout = () => {
  stores.logoutHandle()
  router.push({ name: 'home' })
}
</script>
<style scoped lang="scss">
.user-info-panel {
  width: 493px;
  height: 363px;
  background: $components-userPanel-white;
  border-radius: 12px 12px 12px 12px;
  //   position: absolute;
  //   left: 0;
  .header-user-info-wrap {
    // background-color: #f5f5f5;
    padding: 30px;
    // border-radius: 10px;
  }

  .header-user-basic-wrap {
    display: flex;
    align-items: center;

    .header-user-basic-head-img {
      .user-avatar-placeholder {
        width: 58px;
        height: 58px;
        background-color: $components-userPanel-gray;
        border-radius: 50%;
        border: 2px solid $components-userPanel-gray;
      }
    }

    .header-user-basic-nick-name-wrap {
      margin-left: 15px;

      .header-name-wrap {
        display: flex;
        align-items: center;

        .header-name-left {
          display: flex;
          align-items: center;
          .header-user-basic-nick-name {
            font-size: 18px;
            font-weight: bold;
          }
          .vip-badge {
            width: 55px;
            height: 24px;
            // background-color: #ffcc00;
            margin-left: 10px;
            // border-radius: 5px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .current-process-num {
          margin-left: 20px;
          font-size: 14px;
          color: $components-userPanel-text-gray;

          .label-text {
            color: $components-userPanel-text-light;
          }
        }
      }

      .header-user-basic-rank {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .level {
          font-size: 14px;
          color: $components-userPanel-text-gray;

          .level-num {
            font-weight: bold;
          }
        }

        .process-wrap {
          margin: 0 10px;
          .process-container {
            background-color: $components-userPanel-gray-dark;
            height: 10px;
            border-radius: 5px;
            overflow: hidden;

            .current-process-box {
              position: relative;

              .current-process {
                background-color: $components-userPanel-green;
                height: 100%;
                border-radius: 5px;
              }

              .current-process-stop {
                position: absolute;
                right: 0;
                top: 0;
                display: flex;
                align-items: center;

                .process-stop-placeholder {
                  width: 30px;
                  height: 24px;
                  background-color: $components-userPanel-yellow;
                  border-radius: 5px;
                }

                .process-gif-placeholder {
                  width: 25px;
                  height: 20px;
                  background-color: $components-userPanel-red;
                  border-radius: 5px;
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  .header_user_next_level_tip {
    font-size: 14px;
    color: $components-userPanel-text-light;
    margin-top: 10px;
  }

  .header-user-info-wallet-wrap {
    margin-top: 20px;
    background: url('@assets/icons/nav/wallet-info-bg.webp') no-repeat;
    padding: 18px 24px;
    background-size: 100% 100%;
    width: 433px;
    height: 70px;
    .header-user-info-wallet-info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .wallet-info {
        display: flex;
        // flex-direction: column;
        align-items: center;

        .wallet-info-num {
          .wallet-info-up {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: bold;

            .diamond-icon-placeholder {
              width: 30px;
              height: 30px;
              //   background-color: #4caf50;
              border-radius: 5px;
              //   margin-right: 5px;
            }

            .ticket-icon-placeholder {
              width: 30px;
              height: 30px;
              //   background-color: #2196f3;
              border-radius: 5px;
              //   margin-right: 5px;
            }
          }

          .wallet-info-down {
            font-size: 12px;
            color: $components-userPanel-text-dark;
            margin: 0 15px 0 5px;
            text-align: center;
            // margin-top: 5px;
          }
        }

        .wallet-info-pay-button-wrap {
          //   margin-top: 10px;

          .wallet-info-pay-button {
            display: block;
            // background-color: #4caf50;
            background: url('@assets/icons/nav/wallet-btn.webp') no-repeat;
            background-size: 100% 100%;
            color: $components-userPanel-white;
            // padding: 5px 10px;
            width: 80px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            // border-radius: 5px;
            text-decoration: none;
          }
        }
      }
    }
  }

  .header-user-info-foot-btn-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .foot-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      div {
        width: 60px;
        height: 60px;
        background: linear-gradient(180deg, $components-userPanel-white 0%, $components-userPanel-green-light 100%),
          linear-gradient(180deg, $components-userPanel-white 0%, $components-userPanel-blue-transparent 100%);
        box-shadow: 0px 2px 2px 0px $components-userPanel-shadow-color;
        border-radius: 8px 8px 8px 8px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 30px;
          height: 30px;
        }
      }

      .foot-text {
        margin-top: 5px;
        font-size: 14px;
        color: $components-userPanel-text-gray;
      }
    }
  }

  .header-user-logout-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    .header-user-logout {
      width: 210px;
      height: 38px;
      background: linear-gradient(270deg, $components-userPanel-green-gradient-start 0%, $components-userPanel-green-gradient-end 100%);
      border-radius: 20px 20px 20px 20px;
      line-height: 38px;
      cursor: pointer;
      .header-user-logout-txt {
        color: $components-userPanel-white;
        font-weight: bold;
        text-align: center;
      }
    }
  }
}
</style>

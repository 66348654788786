<template>
  <div class="scoreboard" @click.stop="() => {}">
    <div class="left-section">
      <div class="score-category" @click.prevent="() => goRouter('/score/football')">
        <img src="@assets/icons/scoreboard/sport1.webp" /> 足球比分 <span class="hot">🔥</span>
      </div>
      <div class="score-category" @click.prevent="() => goRouter('/score/basketball')">
        <img src="@assets/icons/scoreboard/sport2.webp" /> 篮球比分 <span class="hot">🔥</span>
      </div>
      <div class="score-category" @click.prevent="() => goRouter('/score/tennis')">
        <img src="@assets/icons/scoreboard/sport5.webp" /> 网球比分
      </div>
      <div class="score-category" @click.prevent="() => goRouter('/score/baseball')">
        <img src="@assets/icons/scoreboard/sport4.webp" /> 棒球比分
      </div>
      <div class="score-category" @click.prevent="() => goRouter('/score/esport')">
        <img src="@assets/icons/scoreboard/sport3.webp" /> 电竞比分
      </div>
    </div>
    <div class="middle-section">
      <div class="score-item" v-for="(res, index) in footballLeagues" :key="index"
        @click.prevent="() => goRouter('/score/football?league=' + res.name)">
        <img :src="res.icon" />
        <span> {{ res.name }}</span>
      </div>
    </div>
    <div class="middle-section">
      <div class="score-item" v-for="(res, index) in basketballLeagues" :key="index"
        @click.prevent="() => goRouter('/score/basketball?league=' + res.name)">
        <img :src="res.icon" />
        <span> {{ res.name }}</span>
      </div>
    </div>
    <div class="middle-section">
      <div class="score-item" v-for="(res, index) in esportsGames" :key="index"
        @click.prevent="() => goRouter('/score/esport?league=' + res.name)">
        <img :src="res.icon" />
        <span> {{ res.name }}</span>
      </div>
    </div>
    <div class="right-section">
      <div class="app-download">
        <p class="download-title">{{ title }}APP下载</p>
        <div class="qr-code-container">
          <qrcode-vue :value="qrCodeUrl" :size="120" level="H" />
        </div>
        <p class="download-tips">扫描下载{{ title }}完整版APP</p>
        <a :href="url" class="download-link">{{ url }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
import QrcodeVue from 'qrcode.vue'
import { ref, watchEffect, getCurrentInstance } from 'vue'

export default {
  name: 'Scoreboard',
  components: {
    QrcodeVue
  },
  setup() {
    const qrCodeUrl = ref('')
    const { proxy } = getCurrentInstance()
    const url = proxy?.$config[__TEMPLATE__]?.url
    const title = proxy?.$config[__TEMPLATE__]?.title
    watchEffect(() => {
      const channelId = localStorage.getItem('channelId') || 'default'
      const linkId = localStorage.getItem('linkId') || ''
      qrCodeUrl.value = `${proxy.$config[__TEMPLATE__].downloadUrl}?channel=${channelId}${linkId ? `&linkId=${linkId}` : ''}`
    })

    return {
      qrCodeUrl,
      title,
      url
    }
  },
  data() {
    return {
      footballLeagues: [
        {
          name: '欧冠',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-ouguan.webp`, import.meta.url).href
        },
        {
          name: '英超',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-yinchao.webp`, import.meta.url).href
        },
        {
          name: '意甲',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-yijia.webp`, import.meta.url).href
        },
        {
          name: '德甲',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-dejia.webp`, import.meta.url).href
        },
        {
          name: '西甲',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-xijia.webp`, import.meta.url).href
        },
        {
          name: '法甲',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-fajia.webp`, import.meta.url).href
        }
      ],
      basketballLeagues: [
        {
          name: 'NBA',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-nba.webp`, import.meta.url).href
        },
        {
          name: 'CBA',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-cba.webp`, import.meta.url).href
        },
        {
          name: 'WNBA',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-wnba.webp`, import.meta.url).href
        },
        {
          name: 'NCAA',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-ncaa.webp`, import.meta.url).href
        }
      ],
      esportsGames: [
        {
          name: 'DOTA2',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-dota2.webp`, import.meta.url).href
        },
        {
          name: 'LOL',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-lol.webp`, import.meta.url).href
        },
        {
          name: 'CSGO',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-csgo.webp`, import.meta.url).href
        },
        {
          name: 'KOG',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-kog.webp`, import.meta.url).href
        }
      ]
    }
  },
  methods: {
    goRouter(path) {
      console.log(this.$router)
      this.$router.push(path)
    },
    tips() {
      message.info('等待系统升级完毕！！')
    }
  }
}
</script>

<style scoped lang="scss">
.scoreboard {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  padding-top: 30px;
}

.left-section,
.middle-section,
.right-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.left-section {
  width: 25%;
}

.middle-section {
  width: 15%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.right-section {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.score-category {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
  font-family: PingFang SC, PingFang SC;
  font-size: 22px;
  color: $components-scoreboard-color;

  .icon {
    margin-right: 10px;
  }

  img {
    width: 20px;
    margin-right: 10px;
  }

  &:hover {
    color: $components-scoreboard-hover-color;
  }

  .hot {
    margin-left: 5px;
    color: $components-scoreboard-hot-color;
  }
}

.score-item {
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    width: 20px;
    margin-right: 10px;
  }
}

.app-download {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  .qr-code-container {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    background: $components-scoreboard-background;
    // border-radius: 8px;
  }

  .download-title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 800;
    font-size: 24px;
    color: $components-scoreboard-color;
    margin-bottom: 10px;
  }

  .download-tips {
    width: 210px;
    height: 36px;
    background: linear-gradient(270deg, $components-scoreboard-gradient-start 0%, $components-scoreboard-gradient-end 100%);
    border-radius: 20px;
    font-weight: 800;
    font-size: 14px;
    color: $components-scoreboard-background;
    line-height: 36px;
    text-align: center;
  }

  p {
    font-size: 20px;
    margin: 5px 0;
  }

  .download-link {
    font-family: PingFang SC, PingFang SC;
    font-weight: 800;
    font-size: 14px;
    color: $components-scoreboard-hover-color;
    line-height: 16px;
    text-align: right;
    font-style: normal;
    text-decoration-line: underline;
    text-transform: none;
  }
}

.phone-mockup img {
  height: auto;
  max-width: 100%;
}
</style>

<template>
  <div class="login-modal-wrap">
    <div class="login-tooltip-info">
      <div class="tooltip-info-up">
        <div class="tooltip-info-title-text">登录后可享受</div>
        <div class="tooltip-info-up-item">
          <img class="tooltip-info-icon" src="@assets/icons/nav/icon-hd-fill.webp" alt="" />
          <span>蓝光10M高清画质，如亲临赛场</span>
        </div>
        <div class="tooltip-info-up-item">
          <img class="tooltip-info-icon" src="@assets/icons/nav/icon-dingyue@2x.webp" alt="" />
          <span>五大联赛/NBA 赛事随心订阅</span>
        </div>
        <div class="tooltip-info-up-item">
          <img class="tooltip-info-icon" src="@assets/icons/nav/icon-dl@2x.webp" alt="" />
          <span>与美女互动看球、发弹幕送礼物</span>
        </div>
      </div>
      <div class="tooltip-info-down">
        <div class="tooltip-info-down-btn" @click="handleLogin">登录</div>
        <div class="tooltip-info-down-txt">未注册手机验证后自动登录</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { AppConfigStore } from '@/stores/app'

const appConfig = AppConfigStore()

// State management for logged-in status
// const isLoggedIn = ref(false)

// Mock function to simulate login process
const handleLogin = () => {
  appConfig.LoginModalHandle(true)
  // Simulate a login process, for example, an API call
  // setTimeout(() => {
  //   // Update the logged-in state
  //   isLoggedIn.value = true
  //   alert('登录成功！享受蓝光高清画质和其他特权！')
  // }, 1000) // Simulate network delay
}

// Watch for login state change (Optional)
// watch(isLoggedIn, (newValue) => {
//   if (newValue) {
//     console.log('User is logged in. Now redirecting to the home page...')
//     // Perform additional actions like redirecting the user
//     // window.location.href = '/home';
//   }
// })
</script>

<style scoped lang="scss">
.login-modal-wrap {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // background-color: #f0f4ff;
  // padding: 20px;
  // position: absolute;
  // top: 30px;
  right: 0;
  // margin-top: 20px;
  // display: none;
}

.login-tooltip-info {
  background-color: $components-login-tooltip-bg;

  border-radius: 10px;
  width: 342px;
  // height: 269px;
  overflow: hidden;
  // background: #ffffff;
  // box-shadow: 0px 14px 26px 0px rgba(77, 133, 217, 0.2);
  // border-radius: 10px;

  // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.tooltip-info-up {
  // margin-bottom: 20px;
  padding: 20px;
  padding-left: 30px;
}

.tooltip-info-title-text {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 18px;
  color: $components-login-tooltip-title;
}

.tooltip-info-up-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .tooltip-info-icon {
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    color: $components-login-tooltip-text;
  }
}

.tooltip-info-down {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  // height: 100px;
  background: $components-login-tooltip-section-bg;
  height: 100px;

  .tooltip-info-down-btn {
    background-color: $components-login-tooltip-btn-bg;
    color: $components-login-tooltip-btn-text;
    padding: 6px 40px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .tooltip-info-down-txt {
    font-size: 14px;
    color: $components-login-tooltip-hint-text;
  }
}
</style>

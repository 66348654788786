<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { AppConfigStore } from '@/stores/app'
import { RouterView } from 'vue-router'
import NavBar from './components/navbar.vue'
import { Affix, Modal } from 'ant-design-vue'
import LoginModal from './components/login.vue'
import { installAPI } from '@/api/home'

const top = ref<number>(0)
const handleOk = () => {}
const appConfig = AppConfigStore()

const urlParams = new URLSearchParams(window.location.search)
const channelId = urlParams.get('channel') || localStorage.getItem('channelId') || ''
const linkId = urlParams.get('linkId') || localStorage.getItem('linkId') || ''

onMounted(() => {
  if (channelId) {
    const data: any = {
      channel: {
        channel: channelId
      },
      reportType: 2
    }
    installAPI(data)
      .then((res) => {
        console.log(res, 'res')
      })
      .catch((err) => {
        console.error(err, 'err')
      })
  }

  localStorage.setItem('channelId', channelId)
  localStorage.setItem('linkId', linkId)
})
</script>

<template>
  <Affix :offset-top="top">
    <NavBar />
  </Affix>

  <div class="main">
    <RouterView />
    <Modal
      v-model:open="appConfig.loginModalShow"
      @ok="handleOk"
      :footer="null"
      :title="null"
      :closable="false"
      style="background: transparent"
      wrapClassName="login_wrapper"
    >
      <LoginModal />
    </Modal>
  </div>
</template>

<style lang="scss">
.main {
  min-height: 100%;
  min-width: 1200px;
}
.login_wrapper {
  .ant-modal {
    .ant-modal-content {
      background: transparent;
      box-shadow: none;
    }
  }
}
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;

  img {
    width: 200px;
    height: 200px;
  }

  p {
    color: $liveDetail-tab-empty-text;
    margin-top: 20px;
  }
}
</style>

<template lang="">
  <div class="download-tip">
    <img src="@assets/icons/nav/logo.webp" class="logo" />
    <div class="qr-code">
      <qrcode-vue :value="qrCodeUrl" :size="180" level="H" />
    </div>
    <div class="button">
      <button @click="goToDownload">扫描下载有料完整版APP</button>
    </div>
    <p class="text">www.dkqiu.com</p>
  </div>
</template>
<script setup>
import { ref, watchEffect, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import QrcodeVue from 'qrcode.vue'

const router = useRouter()
const qrCodeUrl = ref('')
const { proxy } = getCurrentInstance()
// 使用 watchEffect 监听变化并更新二维码URL
watchEffect(() => {
  const channelId = localStorage.getItem('channelId') || 'default'
  const linkId = localStorage.getItem('linkId') || ''
  qrCodeUrl.value = `${proxy.$config[__TEMPLATE__].downloadUrl}?channel=${channelId}${linkId ? `&linkId=${linkId}` : ''}`
})

const goToDownload = () => {
  const channelId = localStorage.getItem('channelId') || 'default'
  const linkId = localStorage.getItem('linkId') || ''
  const query = {
    channel: channelId
  }
  if (linkId) {
    query.linkId = linkId
  }
  router.push({
    path: '/download',
    query
  })
}
</script>
<style lang="scss" scoped>
.download-tip {
  width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .logo {
    width: 100px;
  }
  .qr-code {
    width: 200px;
    height: 200px;
    background: url('@assets/icons/nav/qr-code-bg.webp') no-repeat center center;
    background-size: 100% 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 180px;
      height: 180px;
    }
  }
  .button {
    width: 210px;
    height: 46px;
    background: $components-download-tip-primary;
    border-radius: 39px 39px 39px 39px;
    margin-top: 20px;
    button {
      width: 100%;
      height: 100%;
      background: transparent;
      border: none;
      color: $components-download-tip-white;
    }
  }
  .text {
    font-size: 16px;
    margin-top: 10px;
    color: $components-download-tip-primary;
  }
}
</style>

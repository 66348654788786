interface Config {
  dtiyu: { downloadUrl: string; title: string; url: string }
  dkqiu: { downloadUrl: string; title: string; url: string }
}

const config: Config = {
  dtiyu: { downloadUrl: 'https://download.kanglanshop.cn/', title: '懂体育', url: 'https://download.dtiyu.com' },
  dkqiu: { downloadUrl: 'https://download.ppmnvcf.cn/', title: '懂看球', url: 'https://download.dkqiu.com' }
}

export default config

<template>
  <div class="scoreboard">
    <div class="left-section">
      <div class="score-category" @click.prevent="() => goRouter('1')">
        <img src="@assets/icons/scoreboard/sport1.webp" /> 足球直播 <span class="hot">🔥</span>
      </div>
      <div class="score-category" @click.prevent="() => goRouter('2')">
        <img src="@assets/icons/scoreboard/sport2.webp" /> 篮球直播 <span class="hot">🔥</span>
      </div>
      <div class="score-category" @click.prevent="() => goRouter('5')">
        <img src="@assets/icons/scoreboard/sport5.webp" /> 网球直播
      </div>
      <div class="score-category" @click.prevent="() => goRouter('7')">
        <img src="@assets/icons/scoreboard/sport3.webp" /> 电竞直播
      </div>
      <div class="score-category" @click.prevent="() => goRouter('3')">
        <img src="@assets/icons/scoreboard/sport4.webp" /> 棒球直播
      </div>
      <div class="score-category" @click.prevent="() => goRouter('4')">
        <img src="@assets/icons/scoreboard/sport6.webp" /> 其它直播
      </div>
    </div>
    <div class="middle-section">
      <div class="score-item" v-for="(res, index) in basketballLeagues" :key="index"
        @click.prevent="() => goRouter('2')">
        <img :src="res.icon" />
        <span> {{ res.name }}</span>
      </div>
    </div>
    <div class="middle-section">
      <div class="score-item" v-for="(res, index) in esportsGames" :key="index" @click.prevent="() => goRouter('7')">
        <img :src="res.icon" />
        <span> {{ res.name }}</span>
      </div>
    </div>
    <div class="right-section">
      <div class="app-download">
        <p class="download-title">{{ title }}APP下载</p>
        <div class="qr-code-container">
          <qrcode-vue :value="qrCodeUrl" :size="120" level="H" />
        </div>
        <p class="download-tips">扫描下载{{ title }}完整版APP</p>
        <a :href="url" class="download-link">{{ url }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { ref, watchEffect, getCurrentInstance } from 'vue'

export default {
  name: 'Liveboard',
  components: {
    QrcodeVue
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const qrCodeUrl = ref('')
    const title = proxy?.$config[__TEMPLATE__]?.title
    const url = proxy?.$config[__TEMPLATE__]?.url
    watchEffect(() => {
      const channelId = localStorage.getItem('channelId') || 'default'
      const linkId = localStorage.getItem('linkId') || ''
      qrCodeUrl.value = `${proxy.$config[__TEMPLATE__].downloadUrl}?channel=${channelId}${linkId ? `&linkId=${linkId}` : ''}`
    })

    return {
      qrCodeUrl,
      title,
      url
    }
  },
  data() {
    return {
      basketballLeagues: [
        {
          name: 'NBA',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-nba.webp`, import.meta.url).href
        },
        {
          name: 'CBA',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-cba.webp`, import.meta.url).href
        },
        {
          name: 'WNBA',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-wnba.webp`, import.meta.url).href
        },
        {
          name: 'NCAA',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-ncaa.webp`, import.meta.url).href
        }
      ],
      esportsGames: [
        {
          name: 'DOTA2',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-dota2.webp`, import.meta.url).href
        },
        {
          name: 'LOL',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-lol.webp`, import.meta.url).href
        },
        {
          name: 'CSGO',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-csgo.webp`, import.meta.url).href
        },
        {
          name: 'KOG',
          icon: new URL(`/src/assets/${__TEMPLATE__}/icons/scoreboard/icon-kog.webp`, import.meta.url).href
        }
      ]
    }
  },
  methods: {
    goRouter(type) {
      let path = '/live'
      if (type) {
        path += '?type=' + type
      }
      this.$router.push(path)
    }
  }
}
</script>

<style scoped lang="scss">
.scoreboard {
  display: flex;
  justify-content: space-between;
  //   padding: 20px;
  // background-color: #f9f9f9;
  width: 1200px;
  margin: 0 auto;
  padding-top: 30px;
}

.left-section,
.middle-section,
.right-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.left-section {
  width: 20%;
}

.middle-section {
  width: 15%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.right-section {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.score-category {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  font-family:
    PingFang SC,
    PingFang SC;
  // font-weight: 800;
  font-size: 22px;
  color: $components-liveboard-text-primary;
  img {
    width: 20px;
    margin-right: 10px;
  }
  .icon {
    margin-right: 10px;
  }

  &:hover {
    color: $components-liveboard-text-hover;
  }

  .hot {
    margin-left: 5px;
    color: $components-liveboard-hot;
  }
}

.score-item {
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    width: 20px;
    margin-right: 10px;
  }
}

.app-download {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  .qr-code-container {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .download-title {
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 800;
    font-size: 24px;
    color: $components-liveboard-text-primary;
    margin-bottom: 10px;
  }

  .download-tips {
    width: 210px;
    height: 36px;
    background: linear-gradient(270deg, $components-liveboard-gradient-start 0%, $components-liveboard-gradient-end 100%);
    border-radius: 20px 20px 20px 20px;
    font-weight: 800;
    font-size: 14px;
    color: $components-liveboard-white;
    line-height: 36px;
    text-align: center;
  }

  p {
    font-size: 20px;
    margin: 5px 0;
  }

  .download-link {
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 800;
    font-size: 14px;
    color: $components-liveboard-text-hover;
    line-height: 16px;
    text-align: right;
    font-style: normal;
    text-decoration-line: underline;
    text-transform: none;
  }
}

.phone-mockup img {
  height: auto;
  max-width: 100%;
}
</style>

<template>
  <div class="login-container">
    <div class="login-tabs">
      <button :class="{ active: isPhoneLogin }" @click="isPhoneLogin = true">手机登录</button>
      <button :class="{ active: !isPhoneLogin }" @click="isPhoneLogin = false">密码登录</button>
      <img src="@assets/icons/login/close.webp" class="close_icon" @click="close" />
    </div>
    <div class="login-form">
      <input v-model="phoneNumber" type="text" placeholder="请输入账号" maxlength="11" />
      <input v-if="!isPhoneLogin" v-model="password" type="password" placeholder="请输入密码" />
      <div class="verification" v-if="isPhoneLogin">
        <input v-model="verificationCode" type="text" placeholder="请输入验证码" maxlength="6" />
        <button @click="getVerificationCode">获取验证码</button>
      </div>
      <button class="login-button" @click="loginClickHandle">登录</button>
      <div class="quick-login">
        <button @click="quickLogin">先去逛逛</button>
        <p class="tips">未注册手机验证后自动登录</p>
        <div class="agreement">
          <input type="checkbox" v-model="agreementAccepted" class="check_agreement" />
          <p>我已阅读并接受《<a href="#">服务协议</a>》</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import { AppConfigStore } from '@/stores/app'
import { login, getSmsCode } from '@/api/user'
import { userStore } from '@/stores/user'

const { proxy } = getCurrentInstance()
const appConfig = AppConfigStore()
const userState = userStore()
const isPhoneLogin = ref(true)
const phoneNumber = ref('')
const verificationCode = ref('')
const password = ref('')
const agreementAccepted = ref(false)
const close = () => {
  appConfig.LoginModalHandle(false)
}

const getVerificationCode = () => {
  if (!phoneNumber.value) {
    proxy.$message.error('请输入账号')
    return
  }
  getSmsCode({
    mobile: phoneNumber.value,
    country_code: 86
  })
    .then((res) => {
      console.log(res.code, 'res')
      if (res.code === 200) {
        proxy.$message.success('发送成功')
      } else {
        proxy.$message.error(res.msg)
      }
    })
    .catch((err) => {
      proxy.$message.error(err.msg)
      console.log(err, 'err')
    })
}

const loginClickHandle = () => {
  if (isPhoneLogin.value && !verificationCode.value) {
    proxy.$message.error('请输入验证码')
    return
  }
  if (!isPhoneLogin.value && !password.value) {
    proxy.$message.error('请输入密码')
    return
  }
  if (!agreementAccepted.value) {
    proxy.$message.error('请接受服务协议')
    return
  }
  // Logic to handle login
  console.log('Logging in...')

  login({
    country_code: 86,
    mobile: phoneNumber.value,
    v_code: verificationCode.value,
    pwd: password.value,
    login_type: isPhoneLogin.value ? 0 : 1,
    channel: {
      channel: localStorage.getItem('channelId') || ''
    }
  })
    .then((res) => {
      console.log(res, 'res')
      console.log(userState, ' console.log(userState)')
      userState.userInfo = res.data
      userState.loginHandle(res.data)
      userState.getUserInfo()
      proxy.$message.success('登录成功')
      appConfig.LoginModalHandle(false)
    })
    .catch((err) => {
      proxy.$message.error(err.msg)
      console.log(err, 'err')
    })
}

const quickLogin = () => {
  // Logic for quick browsing
  console.log('Quick browsing...')
}

const getCode = () => {}
</script>

<style scoped lang="scss">
.login-container {
  // background: #e8f5e9;
  border-radius: 15px;
  width: 452px;
  height: 452px;
  margin: auto;
  text-align: center;
  font-family: Arial, sans-serif;
  flex-direction: column;

  display: flex;
  position: relative;
  .login-tabs {
    align-items: center;
    display: flex;
    // justify-content: space-around;
    // margin-bottom: 20px;
    position: relative;
    height: 110px;
    padding: 20px 30px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

    background: url('@assets/icons/login/login-bg.webp') no-repeat;
    background-size: 100% auto;
    background-position: top;
    button {
      background: none;
      border: none;
      // padding: 10px;
      cursor: pointer;
      color: $components-login-white;
      padding: 0;
      padding-bottom: 10px;
      margin-right: 36px;
      &.active {
        font-size: 22px;
        font-weight: bold;
        border-bottom: 1px solid $components-login-white;
      }
    }
    .close_icon {
      position: absolute;
      right: 30px;
      width: 15px;
      top: 30px;
      cursor: pointer;
    }
  }
  .login-form {
    background: $components-login-white;
    height: 363px;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 42px 80px 38px;
    border-radius: 20px;
    input {
      // width: 100%;
      padding: 10px 20px;
      margin-bottom: 20px;
      width: 296px;
      height: 40px;
      background: $components-login-background;
      border-radius: 12px 12px 12px 12px;
      border: none;
      outline: none;
    }
    .verification {
      display: flex;
      justify-content: space-between;
      input {
        width: 178px;
        height: 40px;
        background: $components-login-background;
        border-radius: 12px 12px 12px 12px;
        border: none;
        outline: none;
      }
      button {
        // flex: 1;
        // background-color: #43a047;
        color: $components-login-white;
        border: none;
        // padding: 10px;
        // border-radius: 20px;
        font-size: 14px;
        width: 100px;
        height: 40px;
        cursor: pointer;
        background: url('@assets/icons/login/sms-code.webp') no-repeat;
        background-size: 100% 100%;
      }
    }
    .login-button {
      color: $components-login-white;
      background-color: $components-login-green;
      width: 100%;
      padding: 10px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      background: url('@assets/icons/login/btn.webp') no-repeat;
      background-size: 100% 100%;
      margin-top: 30px;
    }
  }
  .quick-login {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    button {
      background: none;
      border: none;
      color: $components-login-light-green;
      cursor: pointer;
      text-decoration: underline;
      margin-bottom: 10px;
      font-size: 16px;
    }
    .agreement {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      .check_agreement {
        margin-right: 5px;
        width: 15px;
        height: 15px;
        padding: 0;
        margin: 0;
        margin-right: 10px;
        cursor: pointer;
      }
      a {
        color: $components-login-green;
        text-decoration: none;
      }
    }
    .tips {
      font-weight: 500;
      font-size: 12px;
      color: $components-login-gray;
    }
  }
}
</style>

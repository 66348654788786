<template>
  <div class="nav-outer-container" :class="route.name !== 'home' ? 'active' : ''">
    <div class="nav-inner-container" :class="route.name !== 'home' ? 'active' : ''">
      <header class="header" :class="route.name !== 'home' ? 'header-active' : ''">
        <div class="header-container">
          <div class="left-section">
            <div class="logo-section">
              <img 
                src="@assets/icons/nav/logo.webp" 
                alt="Logo" 
                class="logo"
                loading="eager"
                fetchpriority="high"
              />
            </div>
            <nav class="nav">
              <div class="nav-tab" :class="isActive('/') && 'active'">
                <img 
                  :src="isActive('/') && getImageUrl('home.webp')" 
                  alt="首页" 
                  v-show="isActive('/')"
                  loading="lazy"
                  width="18"
                  height="18"
                />
                <router-link to="/" class="title">首页</router-link>
              </div>
              <div class="nav-tab header-score" :class="isActive('/score') && 'active'">
                <img
                  :src="isActive('/score') && getImageUrl('score.webp')"
                  alt="即比分"
                  v-show="isActive('/score')"
                />
                <router-link to="/score" class="title">即时比分</router-link>
                <div class="score-list">
                  <ScoreBoard />
                </div>
              </div>
              <div class="nav-tab header-live" :class="isActive('/live') && 'active'">
                <img
                  :src="isActive('/live') && getImageUrl('live.webp')"
                  alt="直播"
                  v-show="isActive('/live')"
                />
                <router-link to="/live" class="title">直播</router-link>
                <div class="live-list">
                  <LiveBoard />
                </div>
              </div>
              <div class="nav-tab" :class="isActive('/schedule') && 'active'">
                <img
                  :src="isActive('/schedule') && getImageUrl('schedule.webp')"
                  alt="赛程"
                  v-show="isActive('/schedule')"
                />
                <router-link to="/schedule" class="title">赛程</router-link>
              </div>
              <div class="nav-tab" :class="isActive('/info') && 'active'">
                <img
                  :src="isActive('/info') && getImageUrl('info.webp')"
                  alt="有料"
                  v-show="isActive('/info')"
                />
                <router-link to="/info" class="title">有料</router-link>
              </div>
              <div class="nav-tab" :class="isActive('/download') && 'active'">
                <img
                  :src="isActive('/download') && getImageUrl('download-page.webp')"
                  alt="APP下载"
                  v-show="isActive('/download')"
                />
                <router-link to="/download" class="title">APP下载</router-link>
              </div>
            </nav>
          </div>
          <div class="right-section">
            <div class="icon-section" @click="() => goToPage('search')">
              <img 
                src="@assets/icons/nav/search.webp" 
                alt="Search" 
                class="icon"
                loading="lazy"
                width="24"
                height="24"
              />
              <span class="icon-text">搜索</span>
            </div>
            <Popover class="" placement="bottom" @click="download">
              <div class="icon-section">
                <img src="@assets/icons/nav/download.webp" alt="Download" class="icon" />
                <span class="icon-text">下载</span>
              </div>

              <template #content>
                <download-tip />
              </template>
            </Popover>
            <div class="icon-section" @click="() => goToPage('liveApplication')">
              <img src="@assets/icons/nav/broadcast-icon.webp" alt="Broadcast" class="icon" />
              <span class="icon-text">开播</span>
            </div>
            <div class="icon-section" @click="() => goToPage('follow')">
              <img src="@assets/icons/nav/follow.webp" alt="Follow" class="icon" />
              <span class="icon-text">关注</span>
            </div>
            <!-- <div class="icon-section">
              <img src="@assets/icons/nav/night-mode.webp" alt="Night Mode" class="icon" />
              <span class="icon-text">夜间</span>
            </div> -->
            <Popover
              class="button_list"
              :overlayInnerStyle="{ background: 'transparent', padding: 0 }"
              v-if="!userState?.token"
              placement="bottomLeft"
            >
              <!-- <button class="login" @click="login">登录</button>
              <button class="register" @click="login">注册</button> -->

              <div class="avatar" @click="login"></div>
              <template #content>
                <login-tooltip />
              </template>
            </Popover>
            <Popover
              class="avatar_container"
              :overlayInnerStyle="{ background: 'transparent', padding: 0 }"
              v-if="userState?.token"
              placement="bottomLeft"
            >
              <img
                :src="userState?.userInfo?.face != '' ? userState?.userInfo?.face : defaultAvatar"
                class="avatar"
              />
              <!-- <img src="@assets/imgs/default-head-1.webp" class="avatar" /> -->
              <template #content>
                <UserPanel />
              </template>
            </Popover>

            <!-- <div class="user-info" v-if="userState?.userInfo?.token">
              <div class="user-info-left">
                <span class="user-id">{{ userState?.userInfo?.user_info?.nick }}</span>
              </div>
              <div>
                <p class="points">球钻: {{ userState?.userInfo?.user_info?.ball_diamond }}</p>
              </div>
            </div> -->
          </div>
        </div>
      </header>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from 'vue'
import ScoreBoard from './Scoreboard.vue'
import LiveBoard from './Liveboard.vue'
import LoginTooltip from './LoginTooltip.vue'
import UserPanel from './userPanel.vue'
import DownloadTip from './downloadTip.vue'
import { AppConfigStore } from '@/stores/app'
import { useRoute } from 'vue-router'
import { userStore } from '@/stores/user'
import { Popover } from 'ant-design-vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const route = useRoute()
// export default {
//   name: 'FooterNav'
// }

const defaultAvatar = new URL('@assets/imgs/default-head-1.webp', import.meta.url).href
const userState = userStore()
const getImageUrl = (name) => {
  return new URL(`/src/assets/${__TEMPLATE__}/icons/nav/${name}`, import.meta.url).href
}
const appConfig = AppConfigStore()

const download = () => {
  const channelId = localStorage.getItem('channelId') || 'default'
  const linkId = localStorage.getItem('linkId') || ''
  const query = {
    channel: channelId
  }
  if (linkId) {
    query.linkId = linkId
  }
  router.push({
    path: '/download',
    query
  })
}
const isActive = (path) => {
  if (path === '/') {
    return route.path === '/'
  }
  return route.path.startsWith(path)
}

const login = () => {
  // console.log(1111)
  appConfig.LoginModalHandle(true)
}
const goToPage = (type) => {
  // 如果是搜索页面，直接跳转
  if (type === 'search') {
    router.push({ path: '/search' })
    return
  }

  // 其他页面需要登录验证
  if (!userState?.token) {
    login()
    return
  }

  const routes = {
    profile: '/profile',
    follow: '/profile/follow',
    wallet: '/profile/wallet',
    liveApplication: '/profile/live-application',
    vip: '/profile/vip'
  }

  const path = routes[type]
  if (path) {
    router.push({ path })
  }
}

watch(route, (newRoute) => {
  // console.log(newRoute, 'newRoute')
})

// 预加载导航图标
const preloadNavIcons = () => {
  const icons = [
    'home.webp',
    'score.webp',
    'live.webp',
    'schedule.webp',
    'info.webp',
    'download-page.webp'
  ]
  icons.forEach(icon => {
    const img = new Image()
    img.src = getImageUrl(icon)
  })
}

onMounted(() => {
  preloadNavIcons()
})
</script>

<style lang="scss" scoped>
.nav-outer-container {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  height: 70px;
  position: relative;
  z-index: 20;

  background: $components-navbar-bg;
  transition: all 0.3s;

  .active {
    background: $components-navbar-white;
  }
}

.nav-inner-container {
  max-width: 100%;
  min-width: 1200px;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    180deg,
    $components-navbar-gradient-start 0%,
    $components-navbar-gradient-middle 79%,
    $components-navbar-gradient-end 100%
  );
  box-sizing: border-box;
  transition: all 0.3s;

  .active {
    background-color: $components-navbar-white;
    /* 示例背景色 */
  }
}

/* 最小宽度限制 */
@media (max-width: 1400px) {
  .inner-container {
    max-width: 1400px;
  }
}

.header {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;

  &.header-active {
    width: 1200px;
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.left-section {
  display: flex;
  align-items: center;

  .logo-section {
    display: flex;
    align-items: center;
    margin-right: 20px;

    .logo {
      height: 40px;
    }

    .logo-text {
      font-size: 16px;
      font-weight: bold;
      margin-left: 5px;
    }
  }

  .nav {
    display: flex;

    .nav-tab {
      margin: 0 10px;
      font-size: 16px;
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: $components-navbar-text;
      line-height: 16px;
      display: flex;
      align-items: center;
      padding: 7px 10px;
      border-radius: 30px;

      img {
        height: 18px;
        margin-right: 6px;
      }

      .title {
        text-decoration: none;
        color: inherit;

        &:hover {
          color: $components-navbar-green;
        }
      }

      &.active {
        background: $components-navbar-green;

        .title {
          color: $components-navbar-white !important;
        }
      }
    }

    .header-score {
      &:hover {
        .score-list {
          opacity: 1;
          height: 290px !important;
        }
      }
    }

    .header-live {
      &:hover {
        .live-list {
          opacity: 1;
          height: 290px !important;
        }
      }
    }
  }
}

.router-link-active {
  background: $components-navbar-green;

  .title {
    color: $components-navbar-white !important;
  }
}

.right-section {
  display: flex;
  align-items: center;

  .icon-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 0 10px;
    margin-left: 15px;
    cursor: pointer;

    .icon {
      width: 24px;
      height: 24px;
    }

    .icon-search {
      width: 16px;
      height: 16px;
    }

    .icon-text {
      font-size: 12px;
    }

    &:hover {
      color: $components-navbar-green;
    }
  }

  .button_list {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
    position: relative;
    width: 40px;
    height: 40px;
    width: 40px;
    height: 40px;
    background: url('@assets/icons/nav/login-default.png') no-repeat;
    background-size: 100% 100%;

    .avatar {
      border-radius: 50%;
    }

    &:hover {
      width: 40px;
      height: 40px;
      background: url('@assets/icons/nav/login-active.png') no-repeat;
      background-size: 100% 100%;
    }
  }

  .avatar_container {
    width: 40px;
    height: 40px;
    margin-left: 20px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 50%;
    background: $components-navbar-light-gray;

    .avatar {
      height: 100%;
      width: 100%;
      border-radius: 50%;
      background: $components-navbar-light-gray;
    }
  }

  .login,
  .register {
    border: none;
    color: $components-navbar-white;
    outline: none;
    width: 80px;
    height: 30px;
    cursor: pointer;
    font-size: 14px;
    background: url('@assets/icons/nav/login.png') no-repeat;
    background-size: 100% 100%;
  }

  .login {
    margin-left: 20px;
  }

  .register {
    color: $components-navbar-black;
    width: 80px;
    height: 30px;
    cursor: pointer;
    font-size: 14px;
    background: url('@assets/icons/nav/register.png') no-repeat;
    background-size: 100% 100%;
    margin-right: 10px;
    margin-left: 10px;
  }

  .user-info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 10px;
    font-size: 14px;

    .user-info-left {
      display: flex;
      align-items: center;
    }

    .user-id {
      margin-right: 10px;
      font-weight: bold;
    }

    .vip {
      background-color: $components-navbar-vip-bg;
      color: $components-navbar-white;
      padding: 2px 6px;
      border-radius: 4px;
      margin-right: 10px;
    }

    .points {
      color: $components-navbar-gray;
    }
  }
}

.score-list,
.live-list {
  transition:
    height 0.32s ease-in-out,
    opacity 0.52s ease-in-out;
  height: 0;
  z-index: 100;
  opacity: 1;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  text-align: start;
  background: linear-gradient(
    180deg,
    $components-navbar-white 0%,
    $components-navbar-gradient-light 100%
  );
  border-radius: 0px 0px 0px 0px;
  width: 100%;
  margin-top: 60px;
}

:deep(.ant-popover-inner) {
  background: transparent !important;
  box-shadow: none !important;
}

:deep(.ant-popover-inner-content) {
  padding: 0;
}
</style>

import { createRouter, createWebHistory } from 'vue-router'
import NProgress from '@/utils/progress'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/home/home.vue')
    },
    {
      path: '/score',
      redirect: '/score/football'
    },
    {
      path: '/score/football',
      name: 'ScoreFootball',
      component: () => import('@/views/scoreFootball/ScorePage.vue')
    },
    {
      path: '/score/basketball',
      name: 'ScoreBasketball',
      component: () => import('@/views/scoreBasketball/ScorePage.vue')
    },
    {
      path: '/score/tennis',
      name: 'ScoreTennisPage',
      component: () => import('@/views/scoreTennis/ScorePage.vue')
    },
    {
      path: '/score/baseball',
      name: 'ScoreBaseballPage',
      component: () => import('@/views/scoreBaseball/ScorePage.vue')
    },
    {
      path: '/score/esport',
      name: 'ScoreEsport',
      component: () => import('@/views/scoreEsport/ScorePage.vue')
    },
    {
      path: '/score-detail',
      name: 'ScoreDetail',
      component: () => import('@/views/match-detail/index.vue')
    },
    {
      path: '/live',
      name: 'Live',
      component: () => import('@/views/live/index.vue')
    },
    {
      path: '/live/:id',
      name: 'LiveDetail',
      component: () => import('@/views/live-detail/index.vue')
    },
    {
      path: '/schedule',
      name: 'Schedule',
      component: () => import('@/views/schedule/schedule.vue')
    },
    {
      path: '/info',
      name: 'Info',
      component: () => import('@/views/info/info.vue')
    },
    {
      path: '/info/:id',
      name: 'InfoDetail',
      component: () => import('@/views/infoDetail/infoDetail.vue')
    },
    {
      path: '/news',
      name: 'News',
      component: () => import('@/views/news/index.vue')
    },
    {
      path: '/search',
      name: 'Search',
      component: () => import('@/views/search/index.vue')
    },
    {
      path: '/news/:id',
      name: 'NewsDetail',
      component: () => import('@/views/news_detail/index.vue')
    },
    {
      path: '/download',
      name: 'Download',
      component: () => import('@/views/download/index.vue')
    },
    {
      path: '/profile',
      component: () => import('@/views/profile/index.vue'),
      children: [
        {
          path: '',
          name: 'profile',
          component: () => import('@/views/profile/ProfileInfo.vue')
        },
        {
          path: 'wallet',
          name: 'wallet',
          component: () => import('@/views/profile/Wallet.vue')
        },
        {
          path: 'vip',
          name: 'vip',
          component: () => import('@/views/profile/VIP.vue')
        },
        {
          path: 'follow',
          name: 'follow',
          component: () => import('@/views/profile/Follow.vue')
        },
        {
          path: 'collection',
          name: 'collection',
          component: () => import('@/views/profile/Collection.vue')
        },
        {
          path: 'level',
          name: 'level',
          component: () => import('@/views/profile/Level.vue')
        },
        {
          path: 'appointment',
          name: 'appointment',
          component: () => import('@/views/profile/Appointment.vue')
        },
        {
          path: 'message',
          name: 'message',
          component: () => import('@/views/profile/Message.vue')
        },
        {
          path: 'shop',
          name: 'shop',
          component: () => import('@/views/profile/Shop.vue')
        },
        {
          path: 'live-application',
          name: 'liveApplication',
          component: () => import('@/views/profile/LiveApplication.vue')
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/'
    }
  ]
})

// 路由前置守卫
router.beforeEach((to, from, next) => {
  // 开启进度条
  NProgress.start()
  next()
})

// 路由后置守卫
router.afterEach(() => {
  // 关闭进度条
  NProgress.done()
})

export default router

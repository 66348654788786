// 1. 导入核心依赖
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'

// 2. 导入 ant-design-vue 组件
import Antd, { message } from 'ant-design-vue'

// 3. 导入其他插件和工具
import LoadingOverlay from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'
import lazyPlugin from 'vue3-lazy'
import { registerSW } from 'virtual:pwa-register'

// 4. 导入样式和资源
import './utils/reset.css'
import './styles/nprogress.scss'
import errImg from '@assets/imgs/errorimg.png'
import config from './assets/config'

// 5. 导入store相关
import { userStore, useLocalStoragePlugin } from './stores/user'
import { getVisitorId } from '@/utils/utils'

// 6. 初始化访客ID
getVisitorId()

// 7. 创建应用实例
const app = createApp(App)

// 8. 配置 Pinia
const pinia = createPinia()
pinia.use(useLocalStoragePlugin)
app.use(pinia)

// 9. 注册路由
app.use(router)

// 10. 注册 ant-design-vue 组件
// app.use(Table)
// app.use(Modal)
// app.use(Select)
// app.use(Affix)
// app.use(Popover)
// app.use(ConfigProvider)
app.use(Antd)

// 11. 配置全局属性
app.config.globalProperties.$message = message
app.config.globalProperties.$config = config

// 12. 配置插件
app.use(LoadingOverlay, {
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  spinner: 'dots',
  text: 'Loading...',
  textPosition: 'top'
})

app.use(lazyPlugin, {
  error: errImg,
  loading: errImg
})

// 13. 初始化用户信息
const store = userStore()
store.initUserInfo()

// 14. TypeScript 类型声明
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $config: typeof config
    $message: typeof message
  }
}

// 15. 注册 Service Worker
registerSW()

// 16. 挂载应用
app.mount('#app')
